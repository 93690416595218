<template>
    <div class="session-wrapper">
        <div class="session-left"></div>
        <div class="session-right text-center">
            <div class="session-table-cell">
                <div class="session-content">
                    <div class="mb-6 flex justify-center">
                        <img :src="meta[domain].logo" class="img-responsive" height="45" />
                    </div>
                    <h2 class="mb-4">Reset your password</h2>
                    <div class="mb-5">
                        <v-text-field
                            label="New Password"
                            v-model="form.password"
                            :error="form.errors.has('password')"
                            :error-messages="form.errors.get('password')"
                            @input.native="form.errors.clear('password')"
                            @keyup.enter.native="reset"
                            autofocus
                            required
                            type="password"
                        ></v-text-field>
                        <v-text-field
                            label="Confirm New Password"
                            v-model="form.password_confirmation"
                            :error="form.errors.has('password_confirmation')"
                            :error-messages="form.errors.get('password_confirmation')"
                            @input.native="form.errors.clear('password_confirmation')"
                            @keyup.enter.native="reset"
                            required
                            type="password"
                        ></v-text-field>
                        <div class="mt-4">
                            <v-btn
                                @click="reset"
                                block
                                color="primary"
                                class="mb-2"
                                :loading="resetting"
                                >Reset Password</v-btn
                            >
                        </div>
                    </div>
                    <div>
                        <router-link to="/login">Back to login</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/utils/form';
import Http from '@/utils/httpClient';

export default {
    data() {
        return {
            resetting: false,
            form: new Form({
                password: null,
                password_confirmation: null,
                token: null,
                email: null
            })
        };
    },
    computed: {
        ...mapGetters(['meta', 'domain'])
    },
    mounted() {
        this.form.set({
            token: this.$route.params.token,
            email: this.$route.query.email
        });
    },
    methods: {
        reset() {
            this.resetting = true;
            this.form.errors.clear();
            Http.post('password/reset', this.form.payload())
                .then(({ data }) => {
                    this.form.reset();
                    this.$router.push({ name: 'dashboard' }, () => {
                        this.resetting = false;
                        this.$root.$snackbar.open({
                            text: data.message,
                            type: 'success'
                        });
                    });
                })
                .catch(({ response }) => {
                    this.resetting = false;
                    this.form.errors.record(response.data);

                    if (this.form.errors.has('email')) {
                        this.$root.$snackbar.open({
                            text: this.form.errors.get('email'),
                            type: 'error'
                        });
                    }
                });
        }
    }
};
</script>
